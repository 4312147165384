import React, { useState, FC, ReactNode, useMemo, useEffect } from 'react';
import AgendaGroup from '../../Types/AgendaGroup';
import { AgendaItem } from '../../Types/AgendaItem';
import { lastDateBeforeNextMidnight } from '../../Utils/dateUtils';
import { AgendaS, fetchTasks } from '../restapi/taskService';

interface TaskListContextInterface {
  taskListFilter: TaskListFilter;
  taskListContent: TaskListContent;
  totalPages: number;
  changeFilter: (patch: Partial<TaskListFilter>) => void;
  refresh: () => void;
  loading: boolean;
  loadGroups: () => void;
}

type TaskListFilter = {
  selectedTaskGroups: AgendaGroup[];
  selectedDateRange: Date[];
  includeCompleted: boolean;
  page: number;
};

type TaskListContent = {
  agendaItems: AgendaItem[];
  taskGroups: AgendaGroup[];
};

const getDefaultRange = () => {
  const start = new Date(2020, 1, 1);
  return [start, lastDateBeforeNextMidnight()];
};

const DefaultFilter = {
  taskGroups: [],
  selectedTaskGroups: [],
  selectedDateRange: getDefaultRange(),
  includeCompleted: false,
  page: 0,
};

const DefaultContent = {
  agendaItems: [],
  taskGroups: [],
  events: [],
  contacts: [],
};

export const TaskListContext = React.createContext<TaskListContextInterface>({} as TaskListContextInterface);

export const TaskListContextProvider: FC<{ children: ReactNode[] | ReactNode }> = ({ children }) => {
  const [taskListFilter, setTaskListFilter] = useState<TaskListFilter>(DefaultFilter);

  const [taskListContent, setTaskListContent] = useState<TaskListContent>(DefaultContent);

  const [lastModified, setLastModified] = useState(new Date());

  const [loading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const changeFilter = (patch: Partial<TaskListFilter>) => {
    setTaskListFilter((prev) => ({ ...prev, ...patch }));
  };

  const changeContent = (patch: Partial<TaskListContent>) => {
    setTaskListContent((prev) => ({ ...prev, ...patch }));
  };

  const refresh = () => setLastModified(new Date());

  const { selectedTaskGroups, selectedDateRange, page, includeCompleted } = taskListFilter;

  useEffect(() => {
    loadGroups();
  }, [lastModified]);

  const loadGroups = async () => {
    setIsLoading(true);
    const taskGroups = await AgendaS.fetchGroups();
    setIsLoading(false);
    if (taskGroups.length > 0) {
      changeContent({ taskGroups });
      if (selectedTaskGroups.length < 1) {
        changeFilter({ selectedTaskGroups: [...taskGroups] });
      }
    }
  };

  useEffect(() => {
    const selected = selectedTaskGroups.length > 0 ? selectedTaskGroups.map((tg) => tg.id) : undefined;

    setIsLoading(true);

    fetchTasks(page, selectedDateRange[0], selectedDateRange[1], selected, includeCompleted)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((val) => {
            changeContent({ agendaItems: val.content });

            if (val.totalPages !== totalPages) {
              setTotalPages(val.totalPages);
            }
          });
        } else {
          console.error(`could not load tasks of list`);
        }
      })
      .catch(() => {
        console.error(`could not load tasks of list`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [lastModified, selectedTaskGroups, selectedDateRange, page, totalPages, includeCompleted]);

  const value = useMemo(
    () => ({
      taskListFilter,
      taskListContent,
      totalPages,
      changeFilter,
      refresh,
      loading,
      loadGroups,
    }),
    [taskListFilter, taskListContent, totalPages, loading],
  );

  return <TaskListContext.Provider value={value}>{children}</TaskListContext.Provider>;
};

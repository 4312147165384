import AgendaGroup, { AgendaGroupWithItemCountDTO } from '../../Types/AgendaGroup';
import { AgendaItem, CreateAgendaItem } from '../../Types/AgendaItem';
import RecurringTask from '../../Types/RecurringTask';
import { ajaxActions } from '../AjaxActions';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_TASK_SERVICE_URL;

export const fetchTaskGroups = (entityType?: string): Promise<Response> =>
  ajaxActions.get(`${BASE_URL}${entityType ? `/${entityType}` : ``}/agendaGroups`).then((resp) => resp);

export const fetchTasksForGroup = (groupId: string, entityId?: string) =>
  ajaxActions
    .get(`${BASE_URL}/agendaGroups/${groupId}/agendaItems${entityId ? `?entityId=${entityId}` : ''}`)
    .then((resp) => resp);

export const fetchTasks = (page: number, start: Date, end: Date, groupIds?: string[], includeCompleted?: boolean) => {
  const dateUrlFragment = `&start=${start.toISOString()}&end=${end.toISOString()}`;
  const groupUrlFragment = groupIds ? groupIds.map((gid) => `&groupIds=${gid}`).reduce((a, b) => `${a}&${b}`) : '';
  const includeCompleteFragment = includeCompleted !== undefined ? `&includeCompleted=${includeCompleted}` : '';

  return ajaxActions
    .get(`${BASE_URL}/agendaItems?page=${page}${dateUrlFragment}${groupUrlFragment}${includeCompleteFragment}`)
    .then((resp) => resp);
};

export const createTask = (payload: Partial<CreateAgendaItem>) =>
  ajaxActions.put(`${BASE_URL}/agendaItems`, payload).then((resp) => resp);

export const patchTask = (taskId: string, payload: Partial<AgendaItem>) =>
  ajaxActions.patch(`${BASE_URL}/agendaItems/${taskId}`, payload).then((resp) => resp);

export const createTaskGroup = (entityType: string, name: string) =>
  ajaxActions.put(`${BASE_URL}/${entityType}/agendaGroups`, { name }).then((resp) => resp);

export const deleteTask = (taskId: string) => ajaxActions.del(`${BASE_URL}/agendaItems/${taskId}`).then((resp) => resp);

export const createRecurringTask = (payload: Partial<RecurringTask>) =>
  ajaxActions
    .post(`${BASE_URL}/recurringTasks`, {
      ...payload,
      enabled: true,
    })
    .then((resp) => resp);

export const createRecurringEventTasks = (taskId: string) =>
  ajaxActions.put(`${BASE_URL}/recurringTasks/${taskId}/createTasks`).then((resp) => resp);

export const deleteRecurringEventTasks = (taskId: string) =>
  ajaxActions.del(`${BASE_URL}/recurringTasks/${taskId}/deleteTasks`).then((resp) => resp);

export const fetchRecurringTasks = () => ajaxActions.get(`${BASE_URL}/recurringTasks`).then((resp) => resp);

export const deleteRecurringTask = (taskId: string) =>
  ajaxActions.del(`${BASE_URL}/recurringTasks/${taskId}`).then((resp) => resp);

const getCalendarItems = async (start: Date, end: Date, includeCompleted: boolean): Promise<[]> => {
  const res = await ajaxActions.get(
    `${BASE_URL}/agendaItems/calendar/filter?start=${start.toISOString()}&end=${end.toISOString()}&includeCompleted=${includeCompleted}`,
  );
  if (res.ok) {
    return res.json();
  }
  return [];
};

const getById = async (id: string): Promise<AgendaItem | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/agendaItem/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAgendaGroupsWithCounts = async (): Promise<AgendaGroupWithItemCountDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/agendaGroups/counts`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchGroups = async (): Promise<AgendaGroup[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/agendaGroups`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const deleteGroup = async (groupId: string, name: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/agendaGroups/${groupId}`);
  if (res.status === 204) {
    MixpanelS.track(ConstantS.TrackingEvents.TaskGroupDeleted);
    ToastS.info(`delete-group-${groupId}`, `Gruppe ${name} wurde gelöscht`);
    return true;
  }
  return false;
};

export const AgendaS = {
  getCalendarItems,
  getById,
  fetchAgendaGroupsWithCounts,
  fetchGroups,
  deleteGroup,
};

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { ActionIcon, LoadingOverlay, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { useListState } from '@mantine/hooks';
import { IconTrashX } from '@tabler/icons';
import { AgendaGroupWithItemCountDTO } from '../../Types/AgendaGroup';
import { AgendaS } from '../../Service/restapi/taskService';
import BookitupTable from '../../Atoms/BookitupTable';

// eslint-disable-next-line import/prefer-default-export
export const openTasksGroupsModal = (onClose: () => void) =>
  openModal({
    size: 'lg',
    title: (
      <Text weight="bolder" size="xl">
        Gruppenverwaltung
      </Text>
    ),
    children: <TasksGroupsModal />,
    onClose,
  });

const TasksGroupsModal = () => {
  const [list, { filter, setState }] = useListState<AgendaGroupWithItemCountDTO>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AgendaS.fetchAgendaGroupsWithCounts()
      .then(setState)
      .finally(() => setLoading(false));
  }, [setState]);

  const deleteGroup = async (id: string, name: string) => {
    setLoading(true);
    const removed = await AgendaS.deleteGroup(id, name);
    setLoading(false);
    if (removed) {
      filter((ag) => ag.id !== id);
    }
  };

  const mapRowUI = (group: AgendaGroupWithItemCountDTO) => {
    const { id, name, itemCounts } = group;
    return (
      <tr>
        <td>{name}</td>
        <td>{itemCounts}</td>
        <td align="right">
          {itemCounts === 0 && (
            <ActionIcon color="red" onClick={() => deleteGroup(id, name)}>
              <IconTrashX size={16} />
            </ActionIcon>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <BookitupTable>
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: 200 }}>Anzahl der Aufgaben</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>{list.map(mapRowUI)}</tbody>
      </BookitupTable>
    </>
  );
};
